.clients-container {
    width: 100%;
    /*overflow-x: auto;*/
}

/*.clients-container::-webkit-scrollbar{
    width: 12px;
    background-color: #F5F5F5;
}
.clients-container::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 10px;
    background-color: #F5F5F5;
}
.clients-container::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
    background-color: #DBE8FD;
}*/
.clients {
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0 20px 0
}

.document {
    min-width: 220px;
    padding: 20px;
    margin: 20px 20px 5px 5px;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: #ffffff;
    box-shadow: 1px 2px 10px rgba(21, 15, 70, 0.293);
    border-radius: 3px;
    transition: 0.2s;
    cursor: pointer;
}

.title-document {
    max-width: 300px;
    margin: 20px 0 20px 0;
    color: #5D84C2;
    line-height: 30px;
    font-size: 20px;
    font-weight: 300;
    text-align: center;
    overflow: visible;
}

.document:hover {
    transform: scale(1.1);
    background-color: rgb(155, 155, 155);
    ;

}

.document:hover .title-document {
    filter: brightness(30%)
}

.document:hover .img {
    filter: brightness(30%)
}

.download-img {
    display: none;
}

.document:hover .download-img {
    display: block;
    position: absolute;
    z-index: 1000;
}

.img {}

@media screen and (max-width: 920px) {
    .document {
        min-width: 100px;
        width: 120px;
        height: 150px;
        margin: 10px 10px;
        padding: 10px;
    }

    .document {
        justify-content: center;
    }

    .client-img {
        width: 90px;
    }
    .img{
        width: 30px;
    }
    .title-document{
        font-size: 1rem;
        line-height: 1.1rem;
    }
    .download-img {
        scale: 0.5;
    }
    
}