html {
    scroll-behavior: smooth;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0);
    -webkit-tap-highlight-color: transparent;
}

body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Oswald';
    overflow-x: hidden;

}

a {
    text-decoration: none;
}

button:active {
    background-color: #DBE8FD;
}

.container {
    max-width: 1280px;
    margin-right: auto;
    margin-left: auto;
    padding-left: 10%;
    padding-right: 10%;
    overflow-block: visible;
}

.main_header {
    padding-top: 200px;
    font-weight: 500;
    font-size: 64px;
    line-height: 73px;
    color: white;
    text-shadow: 0px 0px 26px rgba(0, 0, 0, 0.70);
}

.main_text {
    font-family: 'Rubik', sans-serif;
    font-size: 1.3rem;
    color: #0B0D2F;
    font-weight: 300;
    white-space: normal;
}

.white {
    color: white;
}

.left_img {
    float: left;
    /* Выравнивание по левому краю */
    margin: 1rem 1rem 1rem 0;
    /* Отступы вокруг картинки */
}
.wrapper {
    padding: 1.5rem 0;
    background: linear-gradient(112.08deg, #ED9577 7.12%, #6D9CE7 95.91%);
    width: 100%;
}

.current_wrapper {
    padding: 0.2rem 0;
}

.wrapper_1 {
    background: linear-gradient(110.4deg, #ED9577 18.33%, #5D84C2 90.12%)
}

.wrapper_dark {
    padding: 50px 0;
    background-color: #0B0D2F;
    width: 100%;
}

.wrapper_white {
    padding: 50px 0;
    background-color: white;
    width: 100%;
}

.wrapper_projects {
    display: flex;
    align-items: center;
    justify-content: center;
}

.mini-header {
    margin: 20px 0;
    color: white;
    line-height: 30px;
    font-size: 24px;
    font-weight: 300;
}

.main_btn {
    background: rgba(255, 255, 255, 0.1);
    border-radius: 7px;
    color: #DBE8FD;
    font-weight: 400;
    font-size: 40px;
    line-height: 59px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    padding: 20px 30px;
    border: 2px solid;
    margin-top: 50px;
    margin-bottom: 70px;
    font-family: 'Oswald';
    height: 90px;
    transition: 0.5s;
}

.main_btn:active {
    background: rgba(255, 255, 255, 0.3);
    ;
}

.main_btn:hover {
    transform: scale(1.05);
    color: white;
    box-shadow: 0px 0px 26px rgba(255, 255, 255, 0.55);
    background: rgba(255, 255, 255, 0.3);
}

.header {
    font-weight: 500;
    font-size: 4rem;
}

.header-modal {
    font-size: 40px;
}

.header-mini-modal {
    line-height: 20px;
}

.light {
    color: white;
}

.dark {
    color: #5D84C2;
}

.blur {
    -webkit-filter: blur(5px);
    -moz-filter: blur(5px);
    -o-filter: blur(5px);
    -ms-filter: blur(5px);
    filter: blur(5px);
}

.contacts {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
}

.contacts-col {
    margin: 10px;
    max-width: 512px;
}

.angle-up {
    color: white;
    font-size: 40px;
    opacity: 1;
}

.angle-btn {
    width: 65px;
    height: 65px;
    margin: 90px 20px;
    border-radius: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #B4C6E5;
    border: none;
    position: fixed;
    bottom: 0;
    right: 0;
    box-shadow: 1px 2px 10px rgba(93, 132, 194, 0.44);
}

.angle-btn:hover {
    background-color: white;
    box-shadow: 1px 2px 20px rgba(93, 132, 194, 0.6);
}

.angle-btn:hover .angle-up {
    color: #5b83c2;
}

.map {
    position: relative;
    overflow: hidden
}

.map-text {
    color: #5D84C2;
    font-size: 12px;
    position: absolute;
    top: 0
}

.map-address {
    color: #5D84C2;
    font-size: 12px;
    position: absolute;
    top: 14px
}

.lng_btn_container {
    display: flex;
    justify-content: center;
}

.lng_btn {
    border: none;
    color: white;
    background: none;
}

.lng_btn:active {
    border-bottom: white;
}

ul.alice-carousel__dots {
    margin: 0.1rem !important;
}

.values-container {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.values-name {
    color: #ED9577;
    font-size: 3rem;
    font-weight: 1000;
    text-align: center;
    margin-top: 1rem;
    margin-bottom: 1rem;
}

.values-text {
    color: #ED9577;
    font-size: 1rem;
    text-align: center;
    display: none;
}

.value-rectangle {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 300px;
    height: 370px;
    border-radius: 7px;
    overflow: hidden;
}

.values {
    padding: 1rem;
    margin: 1rem;
    min-width: 300px;
    display: flex;
    flex-direction: column;
    align-items: center;
    animation-name: slide-in;
    animation-duration: 300ms;
    transition: 0.2s;
    box-shadow: 1px 2px 10px rgba(93, 132, 194, 0.44);
}

.values:hover {
    background-color: #ED9577;
    border-radius: 5px;
    box-shadow: 1px 2px 40px rgba(0, 0, 3, 0.3);
    border: none;

}

.values-img {
    height: 100px;
    width: 100px;
    background-repeat: no-repeat;
    background-size: 50%;
    background-position: center;
    border: 1px solid #ED9577;
    padding: 1rem;
    border-radius: 100px;

}

.values-img-container {
    display: flex;
    width: 100%;
    justify-content: center;
    margin-top: 2rem;
}
.values:hover .values-img-container{
    margin-top: 0;
}
.values-arrow-container{
    display: flex;
    width: 100%;
    justify-content: center;
}
.values-arrow{
    height: 70px;
    width: 70px;
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center;
    background-image: url(./img/about/arrowO.png);
}
.values:hover .values-arrow{
    height: 50px;
    width: 50px;
    background-image: url(./img/about/arrowW.png);
}
#val-img-1 {
    background-image: url(./img/about/1o.png);
}

#val-img-2 {
    background-image: url(./img/about/2o.png);
}

#val-img-3 {
    background-image: url(./img/about/3o.png);
}

.values:hover #val-img-1 {
    background-image: url(./img/about/1w.png);
}

.values:hover #val-img-2 {
    background-image: url(./img/about/2w.png);
}

.values:hover #val-img-3 {
    background-image: url(./img/about/3w.png);
}

.values:hover .values-text {
    color: white;
    display: block;
}

.values:hover .values-name {
    color: white;
    font-size: 2rem;
    margin-top: -2.5rem;
}

.about-img {
    height: 100px;
    width: 100px;
    background-repeat: no-repeat;
    background-size: contain;
    text-align: center;
}
.about-img-container {
    display: flex;
    justify-content: center;
}
.values-container {
    display: flex;
    margin-top: 2rem;
    margin-bottom: 2rem;
}

.about-name {
    color: #5D84C2;
    font-size: 3rem;
    font-weight: 700;
    margin-left: 2rem;
}

.about-text {
    color: #5D84C2;
    margin-left: 2rem;
}

.about {
    padding: 2rem;
    margin: 2rem 0;
    border: 1px solid #5D84C2;
    border-radius: 7px;
    display: flex;
    align-items: center;
    animation-name: slide-in;
    animation-duration: 300ms;
    transition: 0.2s;
}

.about:hover {
    background-color: #B4C6E5;
    box-shadow: 1px 2px 40px rgba(0, 0, 3, 0.3);
    border: none;
}

.about:hover .about-text {
    color: white;
}

.about:hover .about-name {
    color: white;
}

#about-img-1 {
    background-image: url(./img/about/mission.png);
}

#about-img-2 {
    background-image: url(./img/about/vision.png);
}

#about-img-3 {
    background-image: url(./img/about/goals.png);
}

.about:hover #about-img-1 {
    background-image: url(./img/about/missionW.png);
}

.about:hover #about-img-2 {
    background-image: url(./img/about/visionW.png);
}

.about:hover #about-img-3 {
    background-image: url(./img/about/goalsW.png);
}

@keyframes slide-in {
    from {
        transform: translateY(-150px);
    }

    to {
        transform: translateY(0);
    }
}

@media screen and (max-width: 920px) {
    .main_header {
        padding-top: 100px;
        font-size: 54px;
        line-height: 63px;
    }

    .main_btn {
        font-size: 30px;
        line-height: 40px;
        margin-bottom: 20px;
    }

    .header {
        font-size: 60px;
        line-height: 60px;
    }

    .angle-up {
        font-size: 30px;
        color: white;
    }
    .angle-btn:hover {
        background-color: #B4C6E5;
    }

    .angle-btn:hover .angle-up {
        color: white;
    }
    .about{
        flex-direction: column;
        padding: 1rem;
    }
    .about-text{
        font-size: 1rem;
    }
    .about-name{
        font-size: 1.5rem;
    }
.values{
    min-width: 150px;
    padding: 0.5rem;
}
.value-rectangle{
    min-width: 140px;
}
}