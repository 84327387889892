
.technology-container{
    width: 100%;
}
.technology-container::-webkit-scrollbar{
    width: 2px;
    background-color: #000000;
}
.technology-container::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 10px;
    background-color: #000000;
}
.technology-container::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
    background-color: #000000;
}
.technologyies{
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
    margin: 40px 0;
    align-items: center;
}
.technology{
    padding: 5%;
    margin: 20px 20px 20px 20px;
    min-width: 300px ;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    transition: 0.2s;
    cursor: pointer;
    animation-name: slide-in;
    animation-duration: 300ms;
}
.technology:hover{
    transform: scale(1.1);

}
.rectangle-technology{
    position: relative;
    background:  rgba(7, 12, 33, 14);
    width: 300px;
    height: 400px;
    box-shadow: 1px 2px 40px rgba(7, 12, 33, 1);
    border-radius: 7px;
    overflow: hidden;
}
.technology:hover img{
    filter: brightness(30%)
}
.technology:hover .title-technology{
    display: block;
}
.name-technology{
    width: 300px;
    margin: 20px 20px 10px 20px;
    color: #ffffff;
    line-height: 40px;
    font-size: 30px;
    font-weight: 300;
    text-align: center;
    overflow: visible;
}
.title-technology{
    display: none;
    padding: 10px;
    color: white;
    line-height: 24px;
    font-size: 15px;
    font-weight: 300;
    position: absolute;
    top: 10px;
    animation-name: appear;
    animation-duration: 300ms;
    font-family:  'Rubik', sans-serif;
}
.project-img{
    width: 100%;
    height: 100%;
}
.technologies::before{

}
@keyframes appear {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slide-in {
    from {
        transform: translateY(-150px);
    }
    to {
        transform: translateY(0);
    }
}
@media screen and (max-width: 920px){
    .project{
        min-width: 200px ;
    }
    .rectangle{
        width: 250px;
        height: 350px;
    }
    .project:hover{
        transform: scale(1);
    }
    .title-project{
        line-height: 24px;
        font-size: 20px;
        font-weight: 300;
        bottom: 5px;
    }
}