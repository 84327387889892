
.person_{
    min-width: 240px;
    text-align: center;
    margin: 60px 40px 20px 0;
    width: 220px;
    height: 320px;
    box-shadow: 1px 2px 10px rgba(93, 132, 194, 0.44);
    border-radius: 3px;
    transition: 0.2s;
    cursor: pointer;
}
.person-back_{
    background-color: #DBE8FD;
    height: 40%;
    margin-bottom: 80px;
}
.name_{
    font-weight: 400;
    margin: 10px;
}
.title-person_{
    font-size: 20px;
    margin: 0;
}
.person_:hover{
    transform: scale(1.1);
}
.person-img_{
    box-shadow: 1px 2px 10px rgba(93, 132, 194, 0.44);
    transition: 0.2s;
    margin: 0;
}

.team-container{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;

}
.team_{
    display: flex;
    flex-wrap: wrap;
    margin-top: 5rem;
    justify-content: space-around;
}
.person__{
    padding: 0 1rem 0 1rem;
    width: 15rem;
    height: 20rem;
    display:flex;
    flex-direction: column;
    align-items: center;
    transition: 0.2s;
    animation: slide-in;
    animation-duration: 300ms;
}
.person-description_{
    text-align: center;
    transition: 0.2s;
}
.person__:hover .person-img_{
    transform: scale(1.5);
}
.person__:hover .person-description_{
    transform: translateY(40%);
}
.description{
    display: none;
}
.person__:hover .description{
    display: block;
    margin: 0;
    padding: 0;
    color: #0B0D2F;
    font-size: 16px;
    font-weight: 300;
}
.title-person_{
    margin: 0;
    padding: 0;
}

/*.clients-container::-webkit-scrollbar{
    width: 12px;
    background-color: #F5F5F5;
}
.clients-container::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 10px;
    background-color: #F5F5F5;
}
.clients-container::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
    background-color: #DBE8FD;
}*/
.team{
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: start;
}

.person{
    min-width: 370px;
    min-height: 110px;
    text-align: center;
    margin: 20px 40px 20px 0;
    width: 360px;
    display: flex;
    justify-content: start;
    align-items: stretch;
    box-shadow: 1px 2px 10px rgba(93, 132, 194, 0.44);
    cursor: pointer;
    transition: 0.2s;
}
.person a{
    display: flex;
    align-items: center;
}
.person:hover{
    transform: scale(1.1);
}
.person-back{
    background-color: #DBE8FD;
}
.name{
    padding-top: 10px;
    font-weight: 400;
    margin: 0;

}
.title-person{
    font-size: 20px;
    margin: 0;
    padding-right: 10px;
    padding-bottom: 10px;
}
.person-description{
    text-align: left;
    padding-left: 20px;
    margin-top: auto;
    margin-bottom: auto;
}
.person-img{
    border-radius: 50%;
    margin: 10px;
}
.person a{
    background-color: #DBE8FD;
}
.slider {
    position: relative;
    width: 80%;
    height: 500px;
    margin: auto;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.slider-img {
    position: absolute;
    width: 100%;
    height: 500px;
    transition: all 0.2s;
}
.slider-img-prev {
    transform: translateX(-100%);
}
.slider-img-next {
    transform: translateX(100%);
}
@media screen and (max-width: 920px){
    .person{
        margin: 20px 0;
        width: 100%;
        min-width: 0;
    }
}
