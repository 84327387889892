.title_block{
    background: linear-gradient(111.64deg, #1E254B 1.11%, #2A3763 42.14%, #6D9CE7 99.32%);
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    padding-top: 5rem;
}
.text_flex{
    max-width: 60rem;
    min-width: 15rem;
}
.block_container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: end;
}
.reference{
    margin-top: 0
}
.text_block{
    background: #DBE8FD;
    margin: 1rem;
    padding: 1rem;
    box-shadow: 1px 2px 10px rgba(93, 132, 194, 0.44);
    border-radius: 7px;
    overflow: hidden;
    min-width: 10rem;
    max-width: 17rem;
    min-height: 12rem;
}
.header_block_container{
    justify-content: left;
}
.title_block_text{
    padding-left: 2rem;
    max-width: 50rem;
}
.header_page{
    font-size: 2.5rem;
    display: flex;
    align-items: center;
    line-height: 3rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
}
.a_page{
    color: white;
    text-decoration: underline;
}
.header_page_center{
    color: #0B0D2F;
}
.page_main_img{
    width: 11rem;
    object-fit: cover;
    margin: 1rem;
}
.page_img{
    max-width: 35rem;
    min-width: 12rem;
    object-fit: cover;
    margin: 1.5rem;
    border-radius: 7px;
    box-shadow: 1px 2px 10px rgba(93, 132, 194, 0.44);
    
}
.page_img_png{
    box-shadow: none;
 }
.mono_img{
    width: 100%;
    margin-bottom: 2rem;
}
.page_img_container{
    display: flex;
    justify-content: center;
}
.img_container{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}
.arrow{
    width: 2rem;
}
.page_btn{
    background: white;
    border-radius: 7px;
    color: #5D84C2;
/*    margin-right: auto;
    margin-left: auto;*/
    margin-left: 2rem;
    height: 2rem;
    font-weight: 400;
    font-size: 1.3rem;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    border: none;
    font-family: 'Oswald';
    transition: 0.5s;
    animation: 1s pulse_btn infinite alternate;
}
.page_video{
    margin: 1rem;
    height: 15rem;
    width: auto;
}
.page_video_container{
    display: flex;
    flex-wrap: wrap;
}
.download_btn{
    min-width: 15rem;
    justify-content: center;
}
/*.page_btn:hover {
    transform: scale(1.05);
    box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.14);
    color: #5D84C2;
    background: white;
}*/
@keyframes pulse_btn {
    from{
        transform: scale(0.9);
    }
    to{
        transform: scale(1.1);
        box-shadow: 0px 0px 26px rgba(0, 0, 0, 0.14);
    }
}
/*анимация картинок
------------------------------------------------------------------------------------------------*/
.plc_transform_img{
    width: 25rem;
    object-fit: cover;
    position: absolute;
    cursor: pointer;
}
.plc_transform_img_{
    transition: 4s;
    transform: translateX(5rem);
}
.plc_beremiz{

    opacity: 0;
    transition: 3s;
    width: 0;
    object-fit: cover;
    cursor: pointer;
}
.plc_beremiz_{
    opacity: 1;
    width: 25rem;
}
.plc_transform{
    margin-left: auto;
    margin-right: auto;
    width: 25rem;
    height: 25rem;
}
.plc_transform_container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}
.page_btn:hover .plc_transform_container{
    height: 30rem;
}
.plc1{
    z-index: 10;
    transition: 4s;
}
.plc1_{
    transition: 4s;
    transform:  scale(0.7) translateX(19rem);
}
.plc2{
    z-index: 5;
    transition: 4s;
}
.plc3{
    z-index: 0;
    transition: 4s;
}
.plc3_{
    transition: 4s;
    transform:  scale(0.7) translateX(-19rem);
}
.plc4{
    z-index: 3;
    transition: 4s;
}
.plc5_{
    transition-delay: 2s;
    transition: 4s;
    transform: scale(0.7);
}
.plc4_{
    transition-delay: 2s;
    transition: 4s;
    transform: translateY(-3rem) scale(0.7);
}
.plc5{
    z-index: 2;
    transition: 4s;
}
.plc6{
    z-index: 1;
    transition: 4s;
}
.plc6_{
    transition-delay: 2s;
    transition: 4s;
    transform: translateY(3rem) scale(0.7);
}
/*анимация картинок
------------------------------------------------------------------------------------------------*/
.header_page_mini{
    text-align: left;
    font-size: 1.6rem;
    font-weight: 400;
    padding: 0.5rem;
    color: #5D84C2;
    background-color: #DBE8FD;
    box-shadow: 1px 2px 10px rgba(93, 132, 194, 0.44);
    border-radius: 3px;
}
.header_block{
    color: #5D84C2;
    margin-left: auto;
    margin-right: auto;
    font-size: 2rem;
    font-weight: 300;
    text-align: center;
    line-height: 1.7rem;
    overflow: visible;
    max-width: 15rem;
}
.text{
}
.table_page_container{
    box-shadow: 1px 2px 10px rgba(93, 132, 194, 0.44);
    border-radius: 3px;
    padding: 1rem;
    overflow-x: auto;
}
.list_page{
    display: none;
}
.table_page{
    width: 100%;
}
.table_page ul{
    margin: 0;
}
.table_page td{
    vertical-align: top;
    padding: 1rem;
}
.table_page .table_line{
    padding: 0;
}
.table_title{
    font-weight: 500;
}
.border{
    width: 100%;
    height: 1px;
    background-color: #C6D7F2;
    border: none;
    margin: 0;
}
@media screen and (max-width: 920px){
    .page_video{
        width: 15rem;
        height: auto;
    }
    .header_page{
        font-size: 1.8rem;
        line-height: 2rem;
    }
    .list_page{
        display: block;
    }
    .table_page{
        display: none;
    }
    .title_block_text{
        padding: 0;
    }
    .plc_transform{
        width: 12rem;
        height: 12rem;
        vertical-align: center;
    }
    .plc_transform_img{
        width: 12rem;
        margin-left: auto;
        margin-right: auto;
    }
    .plc_transform_container{
        margin-top:0 ;
        flex-direction: column;
        justify-content: space-evenly;
        transition: 4s;
        height: 12rem;
    }
    .plc_transform_container_{
        height: 35rem;
    }
    .page_img{
        max-width: 15rem;
    }
    .plc1_{
        transform:  scale(0.9) translateY(-7rem);
    }
    .plc3_{
        transform:  scale(0.9) translateY(6rem);
    }
    .plc4_{
        transform:  scale(0.9) translateY(-2rem);
    }
    .plc5_{
        transform:  scale(0.9) translateY(0rem);
    }
    .plc6_{
        transform:  scale(0.9) translateY(2rem);
    }
    .plc_beremiz_{
        width: 12rem;
    }
}
