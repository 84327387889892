.clients-container{
    width: 100%;
    /*overflow-x: auto;*/
}
/*.clients-container::-webkit-scrollbar{
    width: 12px;
    background-color: #F5F5F5;
}
.clients-container::-webkit-scrollbar-track{
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 10px;
    background-color: #F5F5F5;
}
.clients-container::-webkit-scrollbar-thumb{
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,.2);
    background-color: #DBE8FD;
}*/
.clients{
    display: flex;
    flex-wrap: wrap;
    margin: 30px 0 20px 0
}

.client{
    min-width: 220px ;
    padding: 20px 5px;
    margin: 20px 20px 5px 5px;
    width: 200px;
    height: 200px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    box-shadow: 1px 2px 10px rgba(93, 132, 194, 0.44);
    border-radius: 3px;
    transition: 0.2s;
    cursor: pointer;
}
.client-name{
   font-size: 1rem;
   text-align: center;
}
.client:hover{
    transform: scale(1.1);
}
.client-img{

}
@media screen and (max-width: 920px){
    .client{
        min-width: 100px;
        width: 110px;
        height: 110px;
        margin: 10px 10px;
        padding: 10px;
    }
    .clients{
        justify-content: center;
    }
    .client-img{
        width: 90px;
    }
    .client-name{
        font-size: 0.5rem;
    }
}